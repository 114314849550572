import React from 'react';

import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectIsSearchDetailedOpen } from '@store/searchForm/selector';

import Header from '@layouts/responsive/Header/Header';

import ButtonNewEd from '@components/controls/Button/ButtonNewEd';
import Search from '@components/search/responsive/SearchForm/Search';

const HeaderSearch = () => {
  const isOpen = useAppSelector(selectIsSearchDetailedOpen);

  return (
    <Header hasShadow>
      <Header.Left />
      <Header.Center>
        <Search />
      </Header.Center>
      <Header.Right>{!isOpen && <ButtonNewEd />}</Header.Right>
    </Header>
  );
};

export default HeaderSearch;
