import { useAppSelector } from '@hooks/redux/useAppSelector';
import { selectDisplayMenu } from '@store/myKpHeader/selector';
import { selectRapidMessageHeight } from '@store/rapidMessage/selector';

type RapidMessageHeightProps = {
  isHeaderFixed?: boolean;
  isKpMenuOpen?: boolean;
  withLayoutTopPadding?: boolean;
  rapidMessageHeight?: number;
};

const useRapidMessageHeight = ({
  isHeaderFixed = false,
  withLayoutTopPadding = false,
}: RapidMessageHeightProps) => {
  const rapidMessageHeight = useAppSelector(selectRapidMessageHeight);
  const isKpMenuOpen = useAppSelector(selectDisplayMenu);
  const mobileMyKPLayoutPadding = isKpMenuOpen ? 6 : rapidMessageHeight + 6;

  const setMobileMainLayoutPadding = ({
    isHeaderFixed,
    isKpMenuOpen,
    withLayoutTopPadding,
    rapidMessageHeight,
  }: RapidMessageHeightProps) => {
    if (withLayoutTopPadding && isKpMenuOpen) {
      return 5.7;
    }
    if (isHeaderFixed && !isKpMenuOpen) {
      return rapidMessageHeight + 5.7;
    }
    return 0;
  };

  const mobileMainLayoutPadding = setMobileMainLayoutPadding({
    isHeaderFixed,
    isKpMenuOpen,
    withLayoutTopPadding,
    rapidMessageHeight,
  });

  return {
    mobileMyKPLayoutPadding,
    mobileMainLayoutPadding,
    rapidMessageHeight,
  };
};

export default useRapidMessageHeight;
