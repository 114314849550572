/* eslint-disable camelcase */
import { memo } from 'react';
import { withRouter } from 'next/router';
import { compose } from 'redux';
import { withFormik } from 'formik';
import isEqual from 'react-fast-compare';

import { setFieldValueForMultiSelect } from 'src/lib/setFieldValueForMultiSelect';

import { SearchProps } from '@store/search/type';

import SearchForms from '@components/search/responsive/SearchForm/SearchForms';

const Search = withFormik({
  mapPropsToValues: ({
    router: {
      query: {
        keywords = '',
        prev_keywords = '',
        categoryId = '',
        groupId = '',
        locationId = '',
        locationRadius = '',
        priceFrom = '',
        priceTo = '',
        currency = '',
        priceText = '',
        condition = [],
        type = '',
        period = '',
        keywordsScope = '',
        hasPhoto = '',
        hasPrice = '',
        exchange = '',
        priceTextDogovor = '',
        priceTextBesplatno = '',
        filterId = '',
        vehicleMakeYearMin = '',
        vehicleMakeYearMax = '',
        vehicleKmMin = '',
        vehicleKmMax = '',
        carFuelType = [],
        carBodyType = [],
        vehiclePowerMin = '',
        vehiclePowerMax = '',
        vehicleCcMin = '',
        vehicleCcMax = '',
        carDoors = '',
        carSeats = '',
        carGearbox = [],
        vehicleAircondition = '',
        vehicleOrigin = '',
        vehicleDisabledPerson = '',
        vehicleOwnerType = [],
        carColors = [],
        carOptions = [],
        carDrives = [],
        carModel = [],
        userId = '',
        immediateAvailable = '',
        deliveryMethod = [],
      },
    },
  }: SearchProps) => {
    return {
      keywords,
      prev_keywords,
      categoryId: Number(categoryId),
      groupId: Number(groupId),
      locationId: Number(locationId),
      locationRadius,
      priceFrom,
      priceTo,
      currency,
      priceText,
      condition: setFieldValueForMultiSelect(condition),
      type,
      period,
      keywordsScope,
      hasPhoto,
      hasPrice,
      exchange,
      priceTextDogovor,
      priceTextBesplatno,
      filterId,
      userId,
      vehicleMakeYearMin,
      vehicleMakeYearMax,
      vehicleKmMin: Number(vehicleKmMin),
      vehicleKmMax: Number(vehicleKmMax),
      carFuelType: setFieldValueForMultiSelect(carFuelType),
      carBodyType: setFieldValueForMultiSelect(carBodyType),
      vehiclePowerMin,
      vehiclePowerMax,
      vehicleCcMin,
      vehicleCcMax,
      carDoors,
      carSeats,
      carGearbox: setFieldValueForMultiSelect(carGearbox),
      vehicleAircondition,
      vehicleOrigin,
      vehicleDisabledPerson,
      vehicleOwnerType,
      carColors: setFieldValueForMultiSelect(carColors),
      carOptions: setFieldValueForMultiSelect(carOptions),
      carDrives: setFieldValueForMultiSelect(carDrives),
      carModel: setFieldValueForMultiSelect(carModel),
      ignoreUserId: 'no',
      immediateAvailable,
      deliveryMethod: setFieldValueForMultiSelect(deliveryMethod),
    };
  },
  enableReinitialize: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleSubmit: () => {},
});

export default compose(withRouter, Search)(memo(SearchForms, isEqual));
