import React from 'react';

import { IconProps } from '@type/icons';

import asIcon from '../asIcon';

const PlusIconMedium = ({ className, stroke, fill }: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill}
    stroke={stroke}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1.5 8H14.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 1.5V14.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default asIcon(PlusIconMedium);
