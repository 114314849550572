/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import dynamic from 'next/dynamic';

import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectIsMobile } from '@store/meta/selector';

const SearchForm = dynamic(
  () => import('@components/search/desktop/SearchForm')
);
const SearchFormMobile = dynamic(
  () => import('@components/search/mobile/SearchForm')
);

const SearchForms = (props: any) => {
  const isMobile = useAppSelector(selectIsMobile);

  return isMobile ? <SearchFormMobile {...props} /> : <SearchForm {...props} />;
};
export default SearchForms;
