import React from 'react';

export interface HeaderCenterProps {
  children: React.ReactNode;
}
const HeaderCenter = ({ children }: HeaderCenterProps) => {
  return <>{children}</>;
};

export default HeaderCenter;
