import values from 'lodash/values';
import { createSelector } from 'reselect';

import { searchFormSelector } from '../rootSelector';

import { SearchFormInitialState } from './type';

export const selectValueName = createSelector(
  searchFormSelector,
  (_, props) => props,
  (
    slicedState: SearchFormInitialState,
    props: { property: string; id: string }
  ) => {
    try {
      const { property, id } = props;
      return property && id ? slicedState[property][id].name : '';
    } catch (e) {
      return '';
    }
  }
);

export const selectPeriod = createSelector(
  searchFormSelector,
  (slicedState: SearchFormInitialState) => {
    return values(slicedState.period);
  }
);

export const selectMyKpPeriod = createSelector(
  searchFormSelector,
  (slicedState: SearchFormInitialState) => {
    return values(slicedState.myKpPeriod);
  }
);

export const selectLocationRadius = createSelector(
  searchFormSelector,
  (slicedState: SearchFormInitialState) => {
    return values(slicedState.locationRadius);
  }
);

export const selectCondition = createSelector(
  searchFormSelector,
  (slicedState: SearchFormInitialState) => {
    return values(slicedState.condition);
  }
);

export const selectAdType = createSelector(
  searchFormSelector,
  (slicedState: SearchFormInitialState) => {
    return values(slicedState.type);
  }
);

export const selectAdKind = createSelector(
  searchFormSelector,
  (slicedState: SearchFormInitialState) => {
    return values(slicedState.adKind);
  }
);

export const selectPriceText = createSelector(
  searchFormSelector,
  (slicedState: SearchFormInitialState) => {
    return values(slicedState.priceText).filter(
      (priceTextValue) => priceTextValue.id !== 'Dogovor'
    );
  }
);

export const selectCurrency = createSelector(
  searchFormSelector,
  (slicedState: SearchFormInitialState) => {
    return values(slicedState.currency);
  }
);

export const selectAdAvailable = createSelector(
  searchFormSelector,
  (slicedState: SearchFormInitialState) => {
    return values(slicedState.immediateAvailable);
  }
);

export const selectAdDeliveryMethod = createSelector(
  searchFormSelector,
  (slicedState: SearchFormInitialState) => {
    return values(slicedState.deliveryMethod);
  }
);

export const selectFocusedFilter = createSelector(
  searchFormSelector,
  (slicedState: SearchFormInitialState) => {
    return slicedState.focusedFilter;
  }
);

export const selectFiltersToRemove = createSelector(
  searchFormSelector,
  (slicedState: SearchFormInitialState) => {
    return slicedState.filtersToRemove;
  }
);

export const selectFiltersToUpdate = createSelector(
  searchFormSelector,
  (slicedState: SearchFormInitialState) => {
    return slicedState.filtersToUpdate;
  }
);

export const selectIsSearchDetailedOpen = createSelector(
  searchFormSelector,
  (slicedState: SearchFormInitialState) => {
    return slicedState.isSearchDetailedOpen;
  }
);

export const selectIsSearchDetailedVisible = createSelector(
  searchFormSelector,
  (slicedState: SearchFormInitialState) => {
    return slicedState.isSearchDetailedVisible;
  }
);

export const selectMyAdsDeleteSoon = createSelector(
  searchFormSelector,
  (slicedState: SearchFormInitialState) => {
    return values(slicedState.deleteSoon);
  }
);
