import React, { ReactElement, ReactNode } from 'react';
import dynamic from 'next/dynamic';
import classNames from 'classnames/bind';

import { useAppSelector } from '@hooks/redux/useAppSelector';
import useRapidMessageHeight from '@hooks/useRapidMessageHeight';

import { selectIsMobile } from '@store/meta/selector';
import { selectModal } from '@store/modal/selector';
import { selectDisplayMenu } from '@store/myKpHeader/selector';
import { selectIsSearchDetailedOpen } from '@store/searchForm/selector';

import { Container, Row } from '@layouts/responsive/Grid';

import Logo from '@components/common/responsive/Logo/Logo';

import HeaderCenter from './HeaderCenter';
import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';

import style from './Header.module.scss';

const setClass = classNames.bind(style);

const AreYouHuman = dynamic(
  () => import('@components/common/responsive/AreYouHuman/AreYouHuman')
);
const LoginPopUp = dynamic(
  () => import('@components/oauth/responsive/LoginPop/LoginPopUp')
);

type ChildProps = {
  type?: typeof HeaderLeft | typeof HeaderCenter | typeof HeaderRight;
};

type HeaderProps = {
  kpBorder?: boolean;
  className?: string;
  children?: ReactElement<ChildProps>[] | ReactElement<ChildProps>;
  classNameLeft?: string;
  classNameRight?: string;
  classNameCenter?: string;
  showLogo?: boolean;
  hasShadow?: boolean;
  isFixed?: boolean;
  canHaveRapidMessage?: boolean;
};

const Header = ({
  kpBorder = false,
  children,
  className,
  classNameLeft,
  classNameRight,
  classNameCenter,
  showLogo = true,
  hasShadow = true,
  isFixed = false,
  canHaveRapidMessage = false,
}: HeaderProps) => {
  const center: Array<ReactNode> = [];
  const left: Array<ReactNode> = [];
  const right: Array<ReactNode> = [];

  React.Children.forEach(children, (child) => {
    if (child?.type === HeaderLeft) {
      left.push(child);
    } else if (child?.type === HeaderCenter) {
      center.push(child);
    } else if (child?.type === HeaderRight) {
      right.push(child);
    }
  });

  const isMobile = useAppSelector(selectIsMobile);
  const activeModal = useAppSelector(selectModal);
  const isOpen = useAppSelector(selectIsSearchDetailedOpen);
  const isKpMenuOpen = useAppSelector(selectDisplayMenu);
  const { rapidMessageHeight } = useRapidMessageHeight({});

  const tempHeaderPaddingTop = isFixed ? rapidMessageHeight : 0;
  const headerPaddingTop =
    isKpMenuOpen || canHaveRapidMessage ? 0 : tempHeaderPaddingTop;

  const headerStyle = setClass(
    {
      header: true,
      isOpen,
      kpBorder,
      hasShadow,
      isFixed,
    },
    className
  );

  return (
    <div style={{ top: `${headerPaddingTop}rem` }} className={headerStyle}>
      <Container>
        <Row>
          <div className={style.headerInner}>
            <div className={setClass({ headerColLeft: true }, classNameLeft)}>
              {left}
              {showLogo && (
                <Logo
                  height={isMobile ? '36px' : '50px'}
                  width={isMobile ? '86px' : '119px'}
                />
              )}
            </div>

            <div
              className={setClass({ headerColMiddle: true }, classNameCenter)}
              data-test="headerMiddle"
            >
              {center}
            </div>

            <div className={setClass({ headerColRight: true }, classNameRight)}>
              {right}
            </div>
          </div>
        </Row>
      </Container>
      {activeModal === 'areYouHuman' && <AreYouHuman />}
      {activeModal === '[LOGIN]' && <LoginPopUp />}
    </div>
  );
};

Header.Left = HeaderLeft;
Header.Center = HeaderCenter;
Header.Right = HeaderRight;

export default Header;
