/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames/bind';

import Spinner from '@components/common/responsive/Spinner/Spinner';

import Button, { ButtonProps } from './Button';

import style from './ButtonPrimaryRed.module.scss';

const setClass = classNames.bind(style);

interface ButtonPrimaryRedProps {
  className?: string;
}

const ButtonPrimaryRed = ({
  className,
  ...props
}: ButtonProps & ButtonPrimaryRedProps) => {
  return (
    <Button
      {...props}
      className={setClass(
        { primaryRed: true, isDisabled: props.isDisabled },
        className
      )}
      loader={<Spinner />}
    />
  );
};

export default ButtonPrimaryRed;
