import React from 'react';

export interface HeaderLeftProps {
  children?: React.ReactNode;
}

const HeaderLeft = ({ children }: HeaderLeftProps) => {
  return <>{children}</>;
};

export default HeaderLeft;
