import React from 'react';

export interface HeaderRightProps {
  children: React.ReactNode;
}

const HeaderRight = ({ children }: HeaderRightProps) => {
  return <>{children}</>;
};

export default HeaderRight;
