import React from 'react';

import { COLOR_TYPES } from '@lib/constants/colors.constants';
import { menuEvents } from '@lib/events/menu.event';

import { NewAdRoute } from 'server/routes';

import ButtonPrimaryRed from '@components/controls/Button/ButtonPrimaryRed';
import PlusIconMedium from '@components/icons/common/PlusIconMedium';

import style from './ButtonNewEd.module.scss';

const ButtonNewEd = () => {
  return (
    <ButtonPrimaryRed
      leading={<PlusIconMedium stroke={COLOR_TYPES.contentOnFill} />}
      className={style.addNewEdButton}
      href={NewAdRoute.generateUrl()}
      doLog={() => menuEvents.clickAdNew()}
      inherit
    >
      Postavite oglas
    </ButtonPrimaryRed>
  );
};

export default ButtonNewEd;
